<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('warehouse-modal', {id: 0},shown)"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.warehouse.label.id') }}</b-th>
                            <b-th>{{ $t('settings.warehouse.label.type') }}</b-th>
                            <b-th>{{ $t('settings.warehouse.label.name') }}</b-th>
                            <b-th>{{ $t('settings.warehouse.label.address') }}</b-th>
                            <b-th>{{ $t('settings.warehouse.label.description') }}</b-th>
                            <b-th>{{ $t('settings.warehouse.label.enable_mail_send') }}</b-th>
                            <b-th>{{ $t('settings.warehouse.label.mail_contacts') }}</b-th>
                            <b-th>{{ $t('settings.warehouse.label.created_at') }}</b-th>
                            <b-th>{{ $t('settings.warehouse.label.updated_at') }}</b-th>
                            <b-th style="min-width: 150px">{{ $t('settings.warehouse.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="warehouse in warehouses" :key="warehouse.id">
                            <b-td>{{ warehouse.id }}</b-td>
                            <b-td>{{ warehouse.type }}</b-td>
                            <b-td>{{ warehouse.name }}</b-td>
                            <b-td>{{ warehouse.address }}</b-td>
                            <b-td>{{ warehouse.description }}</b-td>
                            <b-td>{{ warehouse.enable_mail_send ? $t('common.button.yes') : $t('common.button.no') }}
                            </b-td>
                            <b-td>
                                <div v-for="mail_contact in warehouse.mail_contacts">
                                    <span v-for="mail_element,index in mail_contact" style="white-space: nowrap;">
                                        {{ index }} : {{ mail_element }}
                                        <br/>
                                    </span>
                                </div>
                            </b-td>
                            <b-td>{{ warehouse.created_at | formatDate('DD.MM.YYYY HH:mm')}}</b-td>
                            <b-td>{{ warehouse.updated_at | formatDate('DD.MM.YYYY HH:mm')}}</b-td>
                            <b-td>
                                <b-button
                                    @click="$root.$children[0].openModal('warehouse-modal', {id: warehouse.id},shown)"
                                    size="sm"
                                    variant="primary"
                                    class="mr-1"
                                    :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="edit"/>
                                </b-button>

                                <b-button
                                    @click="$root.$children[0].openModal('shelves-modal', {id: warehouse.id},shown)"
                                    size="sm"
                                    variant="warning"
                                    class="mr-1"
                                    :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="cogs"/>
                                </b-button>

                                <b-button
                                    @click="syncWarehouse(warehouse.id)"
                                    size="sm"
                                    variant="info"
                                    class="mr-1"
                                    :title="$t('common.title.sync_qty')"
                                >
                                    <font-awesome-icon icon="cloud-upload-alt"/>
                                </b-button>

                                <b-button @click="deleteWarehouse(warehouse.id)"
                                          size="sm"
                                          variant="danger"
                                          class="mr-1"
                                          :title="$t('common.title.delete')"
                                >
                                    <font-awesome-icon icon="trash"/>
                                </b-button>

                                <b-button @click="regenerateBarcodesWarehouse(warehouse.id)"
                                          size="sm"
                                          variant="info"
                                          :title="$t('common.title.regenerate_barcodes')"
                                >
                                    <font-awesome-icon icon="sync"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import moment from 'moment'

export default {
    name: 'SettingsWarehouses',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            warehouses: [],
        }
    },
    methods: {
        ...mapGetters('Warehouses', ['getWarehouses']),
        ...mapGetters('Category', ['getCategories']),
        shown() {
            const warehousesFetch = this.$store.dispatch('Warehouses/fetchWarehouses')
            const categories = this.$store.dispatch('Category/fetchCategories')

            Promise.all([warehousesFetch, categories])
                .then(() => {
                    this.warehouses = this.getWarehouses()
                })
                .finally(() => {
                    this.loading = false
                })
            this.$root.afterSettingsUpdate()
        },
        deleteWarehouse(id) {
            this.$removeConfirm('Warehouses/deleteWarehouse', id, this.shown)
        },
        regenerateBarcodesWarehouse(id) {
            this.loading = true
            this.$changeConfirm('Warehouses/regenerateBarcodesWarehouse', id, this.shown)
        },
        syncWarehouse(id) {
            const syncWarehouses = this.$store.dispatch('Warehouses/syncWarehouseQty', id)
            this.loading = true
            Promise.all([syncWarehouses])
                .finally(() => {
                    this.loading = false
                })
        },
        formatDate(timestamp) {
            return moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
        }
    },
    mounted() {
        this.shown()
    }
}
</script>